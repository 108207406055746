import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'mini_program_url',
      name: 'input',
      label: i18n.t('form.referral.target'),
      value: '/packageOnlineStore/pages/welfarePage/index',
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        disabled: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.referral.target') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'referral_invited_reward_type',
      name: 'select',
      label: i18n.t('form.referral.inviteRewardType'),
      options: [],
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        nextChange: true,
        nextChangeProp: 'referral_invited_reward_type',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.referral.inviteRewardType') }), trigger: ['blur', 'change'] }
        ]
      }
    }, {
      span: 8,
      prop: 'referral_invited_reward_id',
      name: 'select',
      label: i18n.t('form.referral.inviteReward'),
      options: [],
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        filterable: true,
        value: 'id',
        key: 'id',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.referral.inviteReward') }), trigger: ['blur', 'change'] }
        ]
      }
    }, {
      span: 8,
      prop: 'referral_user_reward_type',
      name: 'select',
      label: i18n.t('form.referral.userRewardType'),
      options: [],
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        nextChange: true,
        nextChangeProp: 'referral_user_reward_type',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.referral.userRewardType') }), trigger: ['blur', 'change'] }
        ]
      }
    }, {
      span: 8,
      prop: 'referral_user_reward_id',
      name: 'select',
      label: i18n.t('form.referral.userReward'),
      options: [],
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        value: 'id',
        key: 'id',
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.referral.userReward') }), trigger: ['blur', 'change'] }
        ]
      }
    }, {
      span: 8,
      prop: 'begin_time_end_time',
      value: [],
      name: 'date',
      type: 'datetimerange',
      label: i18n.t('form.flexiCombo.period'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        'start-placeholder': i18n.t('form.flexiCombo.startDate'),
        'end-placeholder': i18n.t('form.flexiCombo.endDate'),
        delete: '_destroy',
        format: 'yyyy-MM-dd HH:mm',
        'value-format': 'yyyy-MM-dd HH:mm'
      }
    }]
  }]
}
