// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getOnlineCouponSimplelist } from '@/services/coupon.js'
import { putClientsFormData, getClientsEditDetails } from '@/services/setting.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import moment from 'moment'
// import constant from '@/utils/constant.js'

export default {
  name: 'referralAdd',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      rewardTypes: [
        {
          name: this.$t('form.referral.coupon'),
          value: 'Coupon'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      this.formData[0].createDateItem[1].options = this.rewardTypes
      this.formData[0].createDateItem[3].options = this.rewardTypes
      const initRequest1 = getOnlineCouponSimplelist().then(res => {
        if (res.status === 200) {
          this.online_coupons = res.data.online_coupons
        }
        return res
      })
      const initRequest2 = getClientsEditDetails(this.$store.state.user.client_id).then(res => {
        if (res.status === 200) {
          const client = JSON.parse(JSON.stringify(res.data.client))
          client.begin_time_end_time = [moment(client.referral_begin_date).format('YYYY-MM-DD HH:mm:ss'), moment(client.referral_end_date).format('YYYY-MM-DD HH:mm:ss')]
          this.modifyData = client
        }
        return res
      })
      Promise.all([initRequest1, initRequest2]).then(res => {
        if (this.modifyData.referral_invited_reward_type) {
          this.formData[0].createDateItem[4].options = this.online_coupons
          this.formData[0].createDateItem[2].options = this.online_coupons
        }
        this.$store.state.app.loading.close()
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
      if (prop === 'referral_user_reward_type') {
        this.formData[0].createDateItem[4].options = this.online_coupons
      } else if (prop === 'referral_invited_reward_type') {
        this.formData[0].createDateItem[2].options = this.online_coupons
      }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      obj.referral_begin_date = obj.begin_time_end_time[0]
      obj.referral_end_date = obj.begin_time_end_time[1]
      delete obj.begin_time_end_time
      const params = {
        client: obj
      }
      Object.keys(params.client).forEach(res => {
        if (typeof params.client[res] === 'boolean') {
          if (params.client[res] === true) params.client[res] = '1'
          if (params.client[res] === false) params.client[res] = '0'
        }
      })
      putClientsFormData(params, this.$store.state.user.client_id).then(res => {
        if (res.status === 200 || res.status === 204) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          // setTimeout(() => {
          //   this.$router.push({
          //     name: 'clientsList'
          //   })
          // }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
